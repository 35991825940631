import { Box, Button, Flex, Text } from 'rebass/styled-components';
import React from 'react';
import PropTypes from 'prop-types';

export const DeletedOverlay = ({ discardedMessage, onUndoClick, showDiscardedOverlay, isDiscarded }) => {
  if (!showDiscardedOverlay || !isDiscarded) {
    return null;
  }
  return (
    <Box
      variant="cardOverlay"
      data-testid="deleted-overlay"
      display="flex"
      with={[1]}
      alignContent="stretch"
      justifyContent="center"
    >
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Text textAlign="center">{discardedMessage}</Text>
        <Text display="flex" mt={2}>
          Didn&rsquo;t want to do this?
          <Button data-testid="undo" onClick={onUndoClick} color="primary" variant="anchorLink" ml={1}>
            Undo
          </Button>
        </Text>
      </Flex>
    </Box>
  );
};

DeletedOverlay.propTypes = {
  showDiscardedOverlay: PropTypes.bool,
  isDiscarded: PropTypes.bool,
  onUndoClick: PropTypes.func.isRequired,
  discardedMessage: PropTypes.node,
};

DeletedOverlay.defaultProps = {
  showDiscardedOverlay: false,
  isDiscarded: false,
  discardedMessage: <>We won&rsquo;t show you this anymore</>,
};
