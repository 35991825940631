import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass/styled-components';
import Image from 'next/image';

import { Check } from 'lucide-react';
import { Popover } from '@getro/rombo';
import { useTheme } from 'styled-components';

export const ProfilePicture = forwardRef(
  ({ verifiedSize, imageUrl, name, variant, verified, imageWidth, imageHeight, ...rebassProps }, ref) => {
    const getInitials = (string) =>
      string
        .split(/\s/)
        .map((part) => part.substring(0, 1).toUpperCase())
        .filter((v) => !!v)
        .slice(0, 2)
        .join('');

    const { profilePicture } = useTheme();

    return (
      <Flex tx="profilePicture" variant={`${variant}.wrapper`} data-testid="profile-picture" ref={ref} {...rebassProps}>
        {!imageUrl && (
          <Box
            tx="profilePicture"
            data-testid="text"
            variant={`${variant}.text`}
            as="svg"
            fill="currentColor"
            viewBox="-50 -50 100 100"
            preserveAspectRatio="xMidYMid meet"
          >
            <text sx={profilePicture[variant].text} fontSize="45.5" dy=".34em" textAnchor="middle">
              {getInitials(name)}
            </text>
          </Box>
        )}
        {imageUrl && (
          <Box
            as={Image}
            tx="profilePicture"
            data-testid="image"
            variant={`${variant}.image`}
            src={imageUrl}
            alt={name}
            sx={profilePicture[variant].image}
            width={imageWidth}
            height={imageHeight}
            loading="lazy"
          />
        )}
        {verified && (
          <Popover
            header="Verified Member"
            content="Trusted people in the network have vouched for their professional credibility giving them the ability to request intros."
            trigger={
              <Flex
                tx="profilePicture"
                data-testid="verified"
                variant={`${variant}.verified`}
                height={verifiedSize}
                width={verifiedSize}
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                }}
              >
                <Box strokeWidth="1.5" as={Check} height="75%" width="75%" />
              </Flex>
            }
          />
        )}
      </Flex>
    );
  },
);

ProfilePicture.propTypes = {
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  variant: PropTypes.string,
  verified: PropTypes.bool,
  verifiedSize: PropTypes.string,
  imageWidth: PropTypes.string.isRequired,
  imageHeight: PropTypes.string.isRequired,
};
ProfilePicture.defaultProps = {
  name: '',
  imageUrl: null,
  variant: 'default',
  verified: false,
  verifiedSize: '32px',
};
