export const moveRemoteToEnd = (locs = []) => {
  const locations = [...locs];

  if (!locations) return [];
  const remoteIndex = locations.indexOf('Remote');
  if (remoteIndex !== -1) {
    [locations[remoteIndex], locations[locations.length - 1]] = [
      locations[locations.length - 1],
      locations[remoteIndex],
    ];
  }
  return locations;
};
