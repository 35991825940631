import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@getro/rombo';
import { Box } from 'rebass/styled-components';

export const ButtonToggle = ({ checked, checkedState: CheckedState, unCheckedState: UnCheckedState, ...props }) => (
  <Button data-testid="button-toggle" role="switch" variant="tertiary" type="button" aria-checked={checked} {...props}>
    {checked && <CheckedState data-testid="checkbox-checked" />}
    {!checked && <UnCheckedState data-testid="checkbox-unchecked" />}
  </Button>
);

ButtonToggle.propTypes = {
  checked: PropTypes.bool,
  checkedState: PropTypes.func,
  unCheckedState: PropTypes.func,
};

ButtonToggle.defaultProps = {
  checked: false,
  checkedState: (props) => <Box {...props}>Checked</Box>,
  unCheckedState: (props) => <Box {...props}>Unchecked</Box>,
};
