import { convertFromCents } from './currency';

export const displaySalaryAmount = (number) => {
  const num = typeof number === 'string' ? parseFloat(number) : number;

  if (num >= 1000000) {
    return `${Math.round(num / 100000) / 10}M`;
  }
  if (num >= 1000) {
    return `${Math.round(num / 100) / 10}k`;
  }

  return Math.round(num).toString();
};

export const getCompensationConvertedLabel = ({
  convertedAmountMinCents,
  convertedAmountMaxCents,
  convertedCurrency,
}) =>
  ` (${convertedCurrency} ${displaySalaryAmount(convertFromCents(convertedAmountMinCents))}-${displaySalaryAmount(
    convertFromCents(convertedAmountMaxCents),
  )})`;

export const displayCompensationPeriodLabel = (compensation = {}) => {
  const {
    amountMinCents,
    amountMaxCents,
    currency,
    period,
    offersEquity,
    convertedAmountMinCents,
    convertedAmountMaxCents,
    convertedCurrency,
  } = compensation;
  const converted =
    convertedAmountMinCents && convertedAmountMaxCents && currency !== convertedCurrency
      ? getCompensationConvertedLabel({ convertedAmountMinCents, convertedAmountMaxCents, convertedCurrency, period })
      : '';

  return `${currency} ${displaySalaryAmount(convertFromCents(amountMinCents))}-${displaySalaryAmount(
    convertFromCents(amountMaxCents),
  )} / ${period.toLowerCase()}${converted}${offersEquity ? ' + Equity' : ''}`;
};
