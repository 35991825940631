export const convertToCents = (value) => {
  const numValue = Math.round(parseFloat(value) * 100) / 100;
  let [dollars, cents] = numValue.toString().split('.'); // eslint-disable-line prefer-const

  if (!cents) {
    cents = '00';
  } else if (cents.length === 1) {
    cents += '0';
  }

  return `${dollars}${cents}`;
};

export const convertFromCents = (cents) => {
  const intCents = parseInt(cents, 10);
  const dollars = intCents / 100;

  return parseFloat(dollars.toFixed(2));
};
