import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Text } from 'rebass/styled-components';

const SearchResultsEmpty = ({ content, title }) => (
  <Flex flexDirection="column" alignItems="center" textAlign="center">
    <Text color="text.dark" fontSize={3} fontWeight="semibold" pb={3}>
      {title}
    </Text>
    <Text color="text.main">{content}</Text>
  </Flex>
);

SearchResultsEmpty.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
};

SearchResultsEmpty.defaultProps = {
  content: 'There are no results with this criteria, try changing your filters.',
  title: 'No search results',
};

export default SearchResultsEmpty;
