import { useRouter } from 'next/router';

const usePreviousPageQueryParams = () => {
  const router = useRouter();
  const { query } = router;
  const { companySlug, jobSlug, token, page, ...otherQueryParameters } = query;

  return otherQueryParameters;
};

export default usePreviousPageQueryParams;
