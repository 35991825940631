import { Tooltip } from '@getro/rombo';
import React from 'react';
import { Text } from 'rebass/styled-components';
import PropTypes from 'prop-types';

import { convertFromCents } from '../../../helpers/currency';
import { displaySalaryAmount, getCompensationConvertedLabel } from '../../../helpers/jobs';

export const DisplayCompensationPeriod = ({ compensation, ...rebassProps }) => {
  const {
    amountMinCents,
    amountMaxCents,
    currency,
    period,
    offersEquity,
    convertedAmountMinCents,
    convertedAmountMaxCents,
    convertedCurrency,
  } = compensation;
  const converted =
    convertedAmountMinCents && convertedAmountMaxCents && currency !== convertedCurrency
      ? getCompensationConvertedLabel({ convertedAmountMinCents, convertedAmountMaxCents, convertedCurrency })
      : '';

  return (
    <Text as="p" {...rebassProps}>
      {`${currency} ${displaySalaryAmount(convertFromCents(amountMinCents))}-${displaySalaryAmount(
        convertFromCents(amountMaxCents),
      )} / ${period.toLowerCase()}`}
      {converted ? (
        <Tooltip
          position="top"
          content="Approximate converted salary based on today's rate."
          trigger={
            <Text as="span" sx={{ position: 'relative', zIndex: 2 }}>
              {converted}
            </Text>
          }
        />
      ) : (
        ''
      )}
      {`${offersEquity ? ' + Equity' : ''}`}
    </Text>
  );
};

DisplayCompensationPeriod.propTypes = {
  compensation: PropTypes.object,
  rebassProps: PropTypes.object,
};

DisplayCompensationPeriod.defaultProps = {
  compensation: {},
  rebassProps: {},
};
