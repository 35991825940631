import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { customFiltersSelector } from '../redux/selectors/customFilters';

const useTagsVisibility = ({ network, company }) => {
  const { filters, initialized } = useSelector(customFiltersSelector);
  const { jobBoardFilters } = network;
  const { stageFilter, industryTagsFilter, companySizeFilter } = jobBoardFilters;

  const hasIndustryTagsVisible = useMemo(
    () => industryTagsFilter && company.industryTags?.length > 0,
    [company.industryTags?.length, industryTagsFilter],
  );

  const hasCompanySizeTagsVisible = useMemo(
    () => companySizeFilter && company.headCount > 0,
    [company.headCount, companySizeFilter],
  );

  const hasStageTagsVisible = useMemo(() => stageFilter && company.stage, [company.stage, stageFilter]);

  const visibleCustomFilters = useMemo(() => {
    if (!filters || !initialized) return [];

    return filters.filter(({ visibility }) => visibility === 'visible');
  }, [filters, initialized]);

  const companyTopics = useMemo(() => {
    if (company?.topics?.length > 0) {
      return company.topics.map((topic) => {
        if (typeof topic === 'string') return topic;

        const { name } = topic;
        return name;
      });
    }

    return [];
  }, [company]);

  const visibleTopics = useMemo(() => {
    if (initialized && visibleCustomFilters?.length > 0 && companyTopics.length > 0) {
      return visibleCustomFilters
        .map(({ options }) => options)
        .flat()
        .map(({ name }) => name)
        .filter((value) => companyTopics.includes(value));
    }

    return [];
  }, [initialized, visibleCustomFilters, companyTopics]);

  return {
    hasIndustryTagsVisible,
    hasCompanySizeTagsVisible,
    hasStageTagsVisible,
    visibleTopics,
  };
};

export default useTagsVisibility;
