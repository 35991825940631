import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Text } from 'rebass/styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button } from '@getro/rombo';
import { PageLoader } from '../../atoms/pageLoader';
import { NetworkSchema } from '../../../schema/network';

export const ResultsList = ({
  network,
  isInitialized,
  isLoading,
  canLoadMore,
  onLoadMore,
  items,
  hasActiveJobAlert,
  isLazyLoading,
  listType,
  total,
  children,
  noResults,
  ...rebassProps
}) => {
  const [isInfiniteScroll, setIsInfiniteScroll] = useState(isLazyLoading);

  const hasMore = () => {
    if (items.length === total) {
      return false;
    }
    return isInfiniteScroll;
  };

  return (
    <Flex data-testid={`results-list-${listType[0]}`} flexDirection="column" {...rebassProps}>
      <Box pb="8px" data-testid="header">
        <Text px="5px" fontSize={1} mr={2}>
          {!isLoading && total > 0 && (
            <Flex flexDirection="row" justifyContent="flex-start">
              <Box height="24px" display="flex" alignItems="center" flexGrow={[2, 'unset']}>
                <Text color="text.main">
                  Showing <b>{total.toLocaleString('en-US')}</b> {total === 1 ? listType[0] : listType[1]}{' '}
                </Text>
              </Box>
            </Flex>
          )}
          {isLoading && <>&nbsp;</>}
        </Text>
      </Box>
      {!isInitialized && (
        <Box data-testid="loader">
          <PageLoader />
        </Box>
      )}
      {isInitialized && (
        <Flex m={[0]} pt={1} flexDirection="column" data-testid="body">
          {total === 0 && (
            <Box pb={6} data-testid="no-results">
              {noResults}
            </Box>
          )}
          {total > 0 && (
            <Box
              data-testid="infinite-scroll"
              as={InfiniteScroll}
              next={onLoadMore}
              hasMore={hasMore()}
              loader={
                <>
                  {isLazyLoading && (
                    <Box mt={4} data-testid="scroll-loader" sx={{ overflow: 'hidden' }}>
                      <PageLoader />
                    </Box>
                  )}
                </>
              }
              dataLength={items.length}
              style={{
                maxWidth: '100%',
                overflow: 'hidden',
              }}
              px="5px"
            >
              {children}
            </Box>
          )}
        </Flex>
      )}
      {!isLazyLoading && canLoadMore && (
        <Box pt={5} data-testid="footer" display="flex" justifyContent="center">
          <Button
            loading={isLoading}
            variant="secondary"
            data-testid="load-more"
            onClick={() => {
              onLoadMore();
              setTimeout(() => {
                if (!isInfiniteScroll) {
                  setIsInfiniteScroll(true);
                }
              }, 100);
            }}
          >
            Load more
          </Button>
        </Box>
      )}
    </Flex>
  );
};

ResultsList.propTypes = {
  network: NetworkSchema,
  items: PropTypes.array,
  listType: PropTypes.arrayOf(PropTypes.string).isRequired,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  noResults: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  canLoadMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  isInitialized: PropTypes.bool,
  isLazyLoading: PropTypes.bool,
  hasActiveJobAlert: PropTypes.bool,
  isLoading: PropTypes.bool,
};

ResultsList.defaultProps = {
  network: null,
  items: [],
  total: 0,
  children: null,
  noResults: 'No Results Found',
  canLoadMore: false,
  hasActiveJobAlert: false,
  onLoadMore: () => {},
  isInitialized: true,
  isLazyLoading: false,
  isLoading: false,
};
